import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import "../../App.css";
import "./index.css";
import SimpleReactValidator from "simple-react-validator";
import { loginUser, registerUserSuccess } from "../../actions";
import { userRegister_api } from "../../api/auth";
import * as storageUtils from "../../utils/storageUtils";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      full_name: "",
      email: "",
      password: "",
      errors: [],
      isAPiCalled: false,
    };

    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {}

  handleLoginClick = () => {
    this.props.loginUser();
  };

  setStateFromInput = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      try {
        if (this.state.username.split(" ").length > 1) {
          toast.error("Username does not contain space character");
          return false;
        }

        this.setState({ isAPiCalled: true, errors: [] });
        let apiData = await userRegister_api(this.state);
        // console.log("apiData ", apiData);
        this.setState({ isAPiCalled: false });
        // Set token and login to app.
        storageUtils.setUserData(apiData.user);
        storageUtils.setUserToken(apiData.token);
        // user register success
        this.props.registerUserSuccess(apiData);
        // redirect to homepage
        setTimeout(() => {
          this.props.history.push({
            pathname: "/",
          });
        }, 1000);
      } catch (error) {
        // console.log("error ", error);
        this.setState({
          errors: error.errors,
        });
        this.setState({ isAPiCalled: false });
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };

  render() {
    const {
      full_name,
      email,
      username,
      password,
      isAPiCalled,
      errors,
    } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-5 mx-auto">
            <div id="first">
              <div className="myform form ">
                <div className="logo mb-3">
                  <div className="col-md-12">
                    <h1>Sign up</h1>
                    <span>To continue to webchat</span>
                  </div>
                </div>
                {errors.map((item, index) => {
                  return (
                    <div
                      className="alert alert-danger alert-dismissible fade show"
                      role="alert"
                      key={index}
                    >
                      {item.msg}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={() => {
                          this.setState({
                            errors: [],
                          });
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  );
                })}
                <form method="post" name="login" onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      name="full_name"
                      className="form-control"
                      id="full_name"
                      aria-describedby="emailHelp"
                      placeholder="Name"
                      value={full_name}
                      onChange={this.setStateFromInput}
                    />
                    {this.validator.message(
                      "full_name",
                      full_name,
                      "required|alpha_space|min:2|max:26",
                      { className: "text-danger" }
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={this.setStateFromInput}
                    />
                    {this.validator.message("email", email, "required|email", {
                      className: "text-danger",
                    })}
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="username"
                      className="form-control"
                      id="username"
                      aria-describedby="emailHelp"
                      placeholder="Username"
                      value={username}
                      onChange={this.setStateFromInput}
                    />
                    {this.validator.message(
                      "username",
                      username,
                      "required|min:4",
                      {
                        className: "text-danger",
                      }
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Password"
                      value={password}
                      onChange={this.setStateFromInput}
                    />
                    {this.validator.message(
                      "password",
                      password,
                      "required|min:6|max:14",
                      {
                        className: "text-danger",
                      }
                    )}
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="f-14">
                        Already have an account?{" "}
                        <Link to="/" className="createOneCls">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <button
                        type="submit"
                        className="btn btn-block mybtn"
                        disabled={isAPiCalled}
                      >
                        Sign up
                        {isAPiCalled && (
                          <i className="fa fa-spinner ml-3 fa-pulse " />
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* toast container */}
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    user: auth,
  };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, registerUserSuccess })(Register)
);
