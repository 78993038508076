import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { LOGIN_USER, REGISTER_USER } from "../actions";

import { loginUserSuccess, registerUserSuccess } from "../actions";

function* loginWithEmailPassword({ payload }) {
  try {
    yield put(loginUserSuccess(payload));
  } catch (error) {
    console.log("login error : ", error);
  }
}

function* letRegisterUserSuccess({ payload }) {
  console.log(payload);
  yield put(registerUserSuccess(payload));
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
  yield takeEvery(REGISTER_USER, letRegisterUserSuccess);
}

export default function* rootSaga() {
  yield all([fork(watchLoginUser)]);
}
