import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import "../../App.css";
import "./index.css";
import SimpleReactValidator from "simple-react-validator";
import { loginUserSuccess } from "../../actions";
import { forgotPassword_api } from "../../api/auth";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      errors: [],
      isAPiCalled: false,
      successMsg: "",
    };

    this.validator = new SimpleReactValidator();
  }

  setStateFromInput = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      try {
        this.setState({ isAPiCalled: true, errors: [], successMsg: "" });
        let apiData = await forgotPassword_api(this.state);
        // console.log("apiData ", apiData);
        if (apiData.emailSent) {
          this.setState({
            isAPiCalled: false,
            successMsg: apiData.message,
            errors: [],
          });
        } else {
          this.setState({
            isAPiCalled: false,
            errors: [
              {
                msg: "Oops! someting went wrong, please try again later.",
              },
            ],
          });
        }
      } catch (error) {
        // console.log("error ", error);
        this.setState({
          errors: error.errors,
          successMsg: "",
        });
        this.setState({ isAPiCalled: false });
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };

  render() {
    const { email, isAPiCalled, errors } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-5 mx-auto">
            <div id="first">
              <div className="myform form ">
                <div className="logo mb-3">
                  <div className="col-md-12">
                    <h1>Forgot Password</h1>
                    <span>To continue to webchat</span>
                  </div>
                </div>
                {errors.map((item, index) => {
                  return (
                    <div
                      className="alert alert-danger alert-dismissible fade show"
                      role="alert"
                      key={index}
                    >
                      {item.msg}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={() => {
                          this.setState({
                            errors: [],
                          });
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  );
                })}
                {this.state.successMsg !== "" && (
                  <div
                    className="alert alert-success alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.successMsg}
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                      onClick={() => {
                        this.setState({
                          errors: [],
                          successMsg: "",
                        });
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )}
                <form method="post" name="login" onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        onChange={this.setStateFromInput}
                      />
                      {this.validator.message(
                        "email",
                        email,
                        "required|email",
                        {
                          className: "text-danger",
                        }
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="f-14">
                        <Link to="/" className="createOneCls">
                          {"< Go Back"}
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <button
                        type="submit"
                        className="btn btn-block mybtn"
                        disabled={isAPiCalled}
                      >
                        Submit
                        {isAPiCalled && (
                          <i className="fa fa-spinner ml-3 fa-pulse " />
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    user: auth,
  };
};

export default withRouter(
  connect(mapStateToProps, { loginUserSuccess })(ForgotPassword)
);
