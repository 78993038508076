import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Main from "./views";
import Dashboard from "./modules/dashboard/containers/dashboard";
import Register from "./views/home/register";
import ForgotPassword from "./views/home/forgot-password";

const AuthRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (auth ? <Component {...props} /> : <Redirect to="/" />)}
  />
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { user } = this.props;
    return (
      <div>
        <Router>
          <AuthRoute
            exact
            path="/dashboard"
            auth={user.user}
            component={Dashboard}
          />
          <Route path="/register" component={Register} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/" component={Main} />
        </Router>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    user: auth,
  };
};

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(App);
