import React, { Component } from "react";
import { connect } from "react-redux";
import DashboardView from "./DashboardView";
import * as dashboardActions from "../../actions";
import moment from "moment";
import * as storageUtils from "../../../../utils/storageUtils";
import { setUserLogout, setUserDataSuccess } from "../../../../actions";
import {
  getMe_api,
  getChatList_api,
  getChatDetail_api,
  sendMessage_api,
  searchUser_api,
  letInitiateChat_api,
  manageChatRoom_api,
  chatUpdateStatus_api,
  chatMessageDelete_api,
  chatMessageOpponentDelete_api,
  letChatMarkAsUnread_api,
  letChatMarkAsUnreadFromHere_api,
  letUserLogout_api,
  bothAreSubscribed_api,
  letsChatArchive_api,
  letsDeleteConversation_api,
  letsUploadSingleImage_api,
  updateProfile_api,
  updateIsNotification_api,
  changePassword_api,
  updateShowStatus_api,
  getUserById_api,
} from "../../../../api/auth";

import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import PageVisibility from "react-page-visibility";

import { Helmet } from "react-helmet";

const { client, xml } = require("@xmpp/client");
const debug = require("@xmpp/debug");

export class DashboardContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unreadCountForTitle: 0,
      connectionErrorCount: 0,
      isReconnecting: false,
      isPageVisible: true,
      isCheckedPushCheckbox: false,
      isCheckedShowStatusCheckBox: false,
      myJID: null,
      chatList: [],
      textBoxMessageVal: "",
      apiCalledForChatList: false,
      apiCalledForChatDetail: false,
      activeChat: null,
      chatRoom: [],
      page: 1,
      noData: "",
      searchTerm: "",
      searchResult: [],
      apiCalledForChatInitialize: false,
      userIsTyping: false,
      lastTyping: "",
      isOpenModal: false,
      modalMenuData: null,
      chatIndex: null,
      apiCalledForMarkAsUnread: false,
      cnt: 0,
      isOpenModalForChatRoom: false,
      modalChatRoomData: null,
      apiCalledForMarkAsUnreadFromHere: false,
      apiCalledForChatArchive: false,
      apiCalledForChatDeleteConversation: false,
      isOpenProfileModal: false,
      full_name: "",
      avatar: "",
      apiCaleedForUpdateSetting: false,
      apiCaleedForUpdateisNotification: false,
      apiCaleedForUpdateShawStatus: false,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };

    this.xmpp = null;

    this.handleScrollTop = this.handleScrollTop.bind(this);

    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    if (this.props.user.isLoggedIn && this.xmpp === null) {
      // ask notification permission
      if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
      } else {
        console.log("Notifications are supported");
        Notification.requestPermission();
      }
      this.initiateXMPP();
      this.timeoutCalled();
    }
  }

  showNotification = (fromUser, messageToDIsplay) => {
    if (this.state.chatList.length > 0 && this.props.userData.isNotification) {
      var options = {
        body: messageToDIsplay,
        icon: "https://i.ibb.co/thJsWNJ/default-avatar.png",
        dir: "ltr",
      };
      new Notification(`New message from ${fromUser}`, options);
    }
  };

  handleScrollTop() {
    const chatWindow = document.getElementById("chat-window");
    // console.log(chatWindow.scrollTop);
    if (chatWindow.scrollTop === 0) {
      this.loadMoreMessages();
    }
  }

  async loadMoreMessages() {
    try {
      // console.log("loadMoreMessages");
      const { page, noData, activeChat } = this.state;
      if (noData !== "noData" && activeChat !== null) {
        // get chat list data
        this.setState({ apiCalledForChatDetail: true });
        let chatData = await getChatDetail_api(activeChat.room_id, { page });
        // console.log(chatData);

        const chats = [...this.state.chatRoom];
        chatData.chats.forEach((chat) => chats.unshift(chat));

        this.setState({
          apiCalledForChatDetail: false,
          chatRoom: chats,
          page: chatData.chats.length > 0 ? chatData.nextPage : 1,
          noData: chatData.chats.length === 0 ? "noData" : "",
        });

        if (chatData.chats.length > 0)
          document.getElementById("chat-window").scrollTop = 1500;
      }
    } catch (error) {
      console.log(error);
    }
  }

  timeoutCalled = () => {
    setTimeout(() => {
      this.setState((prevState) => ({
        cnt: prevState.cnt + 1,
      }));
      this.timeoutCalled();
    }, 50000);
  };

  handleContextMenu = (e) => {
    e.preventDefault();
  };

  componentWillUnmount() {
    // clearInterval(this.chatRoomReadInterval);
    // document.removeEventListener("contextmenu", this.handleContextMenu);
  }

  initiateXMPP() {
    this.xmpp = client({
      // service: "ws://13.86.119.78:5443/ws",
      service:
        process.env.NODE_ENV === "development"
          ? "ws://13.86.119.78:5443/ws"
          : // : "wss://13.86.119.78:5280/ws",
            "ws://13.86.119.78:5443/ws",
      domain: "localhost",
      resource: "localhost",
      username: `${this.props.userData.username}@localhost`,
      password: `${this.props.userData.secret}`,
    });
    debug(this.xmpp, process.env.NODE_ENV === "development" ? true : false);

    if (this.xmpp.status !== "connect") {
      this.xmpp.start().catch(console.error);
    }
    this.xmpp.on("offline", () => {
      console.log("offline");
    });
    this.xmpp.on("status", (status) => {
      console.debug("-----------------");
      console.debug(status);
      console.debug("-----------------");
    });

    this.xmpp.on("stanza", async (stanza) => {
      let searchResultFoundSuccess = stanza.getChild(
        "searchResultFoundSuccess"
      );
      if (searchResultFoundSuccess !== undefined) {
        // means we have received search result
        let fromMessage = stanza.attrs;
        let extractUsernameFrom = fromMessage.from.split("@");
        // update lastMessage and unread count
        let oldChatList = [...this.state.chatList];
        let findIndex = oldChatList.findIndex(
          (item) => item.toId.username === extractUsernameFrom[0]
        );
        if (findIndex === -1) {
          setTimeout(() => {
            // fetch chat list
            this.getChatList();
          }, 1000);
        }
      }

      if (stanza.is("presence") && stanza.attrs.type === "subscribe") {
        // send subscribed presence
        (async () => {
          try {
            await this.xmpp.send(
              xml("presence", { type: "subscribed", to: stanza.attrs.from })
            );
          } catch (error) {
            console.log(error.message);
          }
        })();
      }

      // stop receive stanza if no chat users available
      if (this.state.chatList.length === 0) return false;

      if (stanza.is("iq") && stanza.attrs.type === "result") {
        // stanza
        //   .getChild("query", "jabber:iq:roster")
        //   .getChildren("item")
        //   .map((child) => {
        //     console.log(child.attrs.jid);
        //   });
      }

      if (process.env.NODE_ENV === "development") console.log(stanza);
      if (stanza.is("presence")) this.handlePresenceStanzas(stanza);

      if (stanza.is("message")) {
        let chechActOrNot = true;

        let readStat = stanza.getChild("readStat");
        let deleteOwnMsg = stanza.getChild("deleteOwnMsg");
        let typingState = stanza.getChild("typingState");
        let readAllMessages = stanza.getChild("readAllMessages");
        let unReadFromHere = stanza.getChild("unReadFromHere");

        let childrens = stanza.children;
        let fromMessage = stanza.attrs;
        // console.log(fromMessage);
        if (
          this.state.activeChat !== null &&
          fromMessage.type === "chat" &&
          readStat === undefined
        ) {
          let currentToId = `${this.state.activeChat.toId.username}@localhost/localhost`;
          // console.log(currentToId);
          // check both are same
          if (fromMessage.from === currentToId && deleteOwnMsg === undefined) {
            let content = stanza.getChild("body").text();
            let tmstmp = stanza.getChild("timestamp").text();
            // add to chat room array
            const data = {
              room_id: this.state.activeChat.room_id,
              from_id: this.state.activeChat.to_id,
              to_id: this.state.activeChat.from_id,
              fromId: this.state.activeChat.toId,
              toId: this.state.activeChat.fromId,
              type: "text",
              content: content,
              createdAt: moment.now(),
              status: 0,
              deletedAt: null,
              msgTimestamp: tmstmp,
            };
            // console.log(data);
            this.setState((prevState) => ({
              ...prevState,
              chatRoom: [...prevState.chatRoom, data],
              userIsTyping: false,
            }));
            setTimeout(() => {
              // scroll to end
              this.scroolToBottom();
            }, 500);

            if (childrens.length === 2) {
              chechActOrNot = false;
              // length 2 means message has timestamp
              // console.log(
              //   "checkTmSp in active ",
              //   stanza.getChild("timestamp").text()
              // );
              let checkTmSp = stanza.getChild("timestamp").text();

              // api for update the status
              try {
                chatUpdateStatus_api({
                  status: 2,
                  msgTimestamp: checkTmSp,
                });
              } catch (error) {
                console.log(error);
              }

              try {
                // send ack to sender that msg is read
                const message = xml(
                  "message",
                  {
                    type: "ack",
                    to: fromMessage.from,
                  },
                  xml("readStat", {}, checkTmSp)
                );
                await this.xmpp.send(message);
              } catch (error) {
                console.log(error);
              }

              let extractUsernameFrom = fromMessage.from.split("@");

              // update lastMessage
              let oldChatList = [...this.state.chatList];
              let findIndex = oldChatList.findIndex(
                (item) => item.toId.username === extractUsernameFrom[0]
              );
              if (findIndex !== -1) {
                oldChatList[findIndex] = {
                  ...oldChatList[findIndex],
                  lastMessage: {
                    ...oldChatList[findIndex].lastMessage,
                    content: content,
                    createdAt: moment.utc().format(),
                    msgTimestamp: checkTmSp,
                    deletedAt: null,
                    status: 2,
                  },
                };
                this.setState({ chatList: oldChatList });

                if (!this.state.isPageVisible) {
                  // show notification if user on another tab
                  this.showNotification(extractUsernameFrom[0], content);
                }
              }
            }
          }
        }

        // set typing presence to true
        if (this.state.activeChat !== null && typingState !== undefined) {
          let currentToId = `${this.state.activeChat.toId.username}@localhost/localhost`;
          // console.log(currentToId);
          // check both are same
          if (fromMessage.from === currentToId) {
            this.setState({ userIsTyping: true, lastTyping: moment() });
            setTimeout(() => {
              var duration = moment.duration(
                moment().diff(moment(this.state.lastTyping))
              );
              if (duration.asSeconds() >= 1) {
                this.setState({ userIsTyping: false });
              }
            }, 1000);
          }
        }

        // set all my message status to 2
        if (this.state.activeChat !== null && readAllMessages !== undefined) {
          let currentToId = `${this.state.activeChat.toId.username}@localhost/localhost`;
          // console.log(currentToId);
          // check both are same
          if (fromMessage.from === currentToId) {
            let oldChatRoom = [...this.state.chatRoom];
            const newChatRoom = oldChatRoom.map((chat) =>
              [0, 1].includes(chat.status) ? { ...chat, status: 2 } : chat
            );
            this.setState({ chatRoom: newChatRoom });
          }
        }

        // set all my message status to 1 [unReadFromHere]
        if (this.state.activeChat !== null && unReadFromHere !== undefined) {
          let currentToId = `${this.state.activeChat.toId.username}@localhost/localhost`;
          // console.log(currentToId);
          // check both are same
          if (fromMessage.from === currentToId) {
            let oldChatRoom = [...this.state.chatRoom];
            const newChatRoom = oldChatRoom.map((chat) =>
              [0, 2].includes(chat.status) ? { ...chat, status: 1 } : chat
            );
            this.setState({ chatRoom: newChatRoom });
          }
        }

        // console.log(childrens);
        // send delivered ack
        if (childrens.length === 2 && chechActOrNot) {
          // length 2 means message has timestamp
          // message delivered

          // console.log("checkTmSp  ", stanza.getChild("timestamp").text());
          let newContent = stanza.getChild("body");
          let checkTmSp = stanza.getChild("timestamp");
          if (newContent && checkTmSp) {
            try {
              // send ack to sender
              const message = xml(
                "message",
                {
                  type: "ack",
                  to: fromMessage.from,
                },
                xml("body", {}, checkTmSp.text())
              );
              await this.xmpp.send(message);
            } catch (error) {
              console.log(error);
            }

            let extractUsernameFrom = fromMessage.from.split("@");
            // update lastMessage and unread count
            let oldChatList = [...this.state.chatList];
            let findIndex = oldChatList.findIndex(
              (item) => item.toId.username === extractUsernameFrom[0]
            );
            if (findIndex !== -1) {
              oldChatList[findIndex] = {
                ...oldChatList[findIndex],
                isChatArchive: false,
                deletedAt: null,
                lastMessage: {
                  ...oldChatList[findIndex].lastMessage,
                  content: newContent.text(),
                  createdAt: moment.utc().format(),
                  msgTimestamp: checkTmSp.text(),
                  deletedAt: null,
                  staus: 1,
                },
                unreadCount: oldChatList[findIndex].unreadCount + 1,
              };
              this.setState({ chatList: oldChatList }, () => {
                // call unread title func
                this.setTitleUnreadCount();
              });

              // show notification
              this.showNotification(extractUsernameFrom[0], newContent.text());
            }
          }
        }

        if (
          childrens.length === 1 &&
          readStat === undefined &&
          typingState === undefined &&
          readAllMessages === undefined &&
          searchResultFoundSuccess === undefined &&
          unReadFromHere === undefined &&
          deleteOwnMsg === undefined
        ) {
          // length 1 means delivered acknowledge return success
          // console.log("successACK  ", parseInt(stanza.getChild("body").text()));
          // console.log(this.state.chatRoom);
          let oldChatRoom = [...this.state.chatRoom];
          let findIndex = oldChatRoom.findIndex(
            (item) =>
              item.msgTimestamp === parseInt(stanza.getChild("body").text())
          );
          if (findIndex !== -1) {
            oldChatRoom[findIndex] = {
              ...oldChatRoom[findIndex],
              status: 1,
            };
            this.setState({ chatRoom: oldChatRoom });
            try {
              // api for update the status
              chatUpdateStatus_api({
                status: 1,
                msgTimestamp: parseInt(stanza.getChild("body").text()),
              });
            } catch (error) {
              console.log(error);
            }
          }
        }

        if (readStat !== undefined) {
          // means we have received read ack
          let oldChatRoom = [...this.state.chatRoom];
          let findIndex = oldChatRoom.findIndex(
            (item) =>
              item.msgTimestamp === parseInt(stanza.getChild("readStat").text())
          );
          if (findIndex !== -1) {
            oldChatRoom[findIndex] = {
              ...oldChatRoom[findIndex],
              status: 2,
            };
            this.setState({ chatRoom: oldChatRoom });
          }
        }

        if (deleteOwnMsg !== undefined) {
          let nowMoment = moment.now();
          // means we have delete the relatively message from chatroom
          let oldChatRoom = [...this.state.chatRoom];
          let findIndex = oldChatRoom.findIndex(
            (item) =>
              parseInt(item.msgTimestamp) ===
              parseInt(stanza.getChild("deleteOwnMsg").text())
          );
          if (findIndex !== -1) {
            oldChatRoom[findIndex] = {
              ...oldChatRoom[findIndex],
              deletedAt: nowMoment,
            };
            this.setState({ chatRoom: oldChatRoom });
          }

          let extractUsernameFrom = fromMessage.from.split("@");
          // check whether lastmessage is same as current
          let oldChatList = [...this.state.chatList];
          let findIndexNew = oldChatList.findIndex(
            (item) => item.toId.username === extractUsernameFrom[0]
          );

          // console.log("findIndexNew >> ", findIndexNew);
          // console.log(
          //   parseInt(oldChatList[findIndexNew].lastMessage.msgTimestamp)
          // );
          // console.log(parseInt(stanza.getChild("deleteOwnMsg").text()));

          if (findIndexNew !== -1) {
            // check if lastMessage is the same that is currently deleted
            if (
              oldChatList[findIndexNew].lastMessage !== null &&
              parseInt(oldChatList[findIndexNew].lastMessage.msgTimestamp) ===
                parseInt(stanza.getChild("deleteOwnMsg").text())
            ) {
              // update lastMessage into chat list
              oldChatList[findIndexNew] = {
                ...oldChatList[findIndexNew],
                lastMessage: {
                  ...oldChatList[findIndexNew].lastMessage,
                  deletedAt: nowMoment,
                },
              };
              this.setState({ chatList: oldChatList });
            }
          }
        }
      }
    });

    this.xmpp.on("online", async (address) => {
      // console.log(address.toString());
      // Makes itself available
      try {
        await this.xmpp.send(xml("presence"));
      } catch (error) {
        console.log(error);
      }

      // set my JID
      this.setState({
        myJID: `${this.props.userData.username}@localhost/localhost`,
      });

      // Get user's roster list
      // await this.xmpp.send(
      //   xml(
      //     "iq",
      //     { type: "get", id: "roster", xmlns: "jabber:client" },
      //     xml("query", "jabber:iq:roster")
      //   )
      // );

      // get current userData
      this.getMeData();

      // get chat list
      this.getChatList();
    });

    this.xmpp.on("error", (err) => {
      console.log(err.message);
      if (err.message === "conflict - Replaced by new connection") {
        window.location.reload();
        // Location.reload();
      }
      // handle connection error
      if (err.message.includes("WebSocket ECONNERROR")) {
        console.log("into WebSocket ECONNERROR");
        if (this.state.connectionErrorCount === 0) {
          toast.error("Connection error occurred...");
        }
        this.setState((prevState) => ({
          connectionErrorCount: prevState.connectionErrorCount + 1,
        }));
      }
      // handle Not-Authorized error
      if (err.message.includes("not-authorized")) {
        console.log("into not-authorized");
        if (this.state.connectionErrorCount === 0) {
          toast.error("Not-Authorized - Invalid username or password");
        }
        this.setState((prevState) => ({
          connectionErrorCount: prevState.connectionErrorCount + 1,
        }));
        setTimeout(() => {
          this.handleLogout();
        }, 4000);
      }
    });

    this.xmpp.reconnect.on("reconnecting", (data) => {
      // display message to the user that connection is reconnecting...
      if (this.state.isReconnecting === false) {
        this.setState(
          {
            isReconnecting: true,
          },
          () => {
            toast.warning("Reconnecting to the server...", {
              position: "bottom-center",
              autoClose: false,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            });
          }
        );
      }
    });

    this.xmpp.reconnect.on("reconnected", (data) => {
      // display connection established message if previously error occurred
      if (this.state.connectionErrorCount > 0) {
        toast.dismiss();
        // reset the counter and reconnecting flag
        this.setState(
          {
            connectionErrorCount: 0,
            isReconnecting: false,
          },
          () => {
            toast.success("Connection established successfully");
          }
        );
      }
    });
  }
  getMeData = async () => {
    try {
      let apiData = await getMe_api();
      // console.log(apiData);
      // Set token and login to app.
      storageUtils.setUserData(apiData.user);
      // user register success
      this.props.setUserDataSuccess(apiData);
    } catch (err) {
      console.log(err);
      toast.error(err.errors[0].msg);
    }
  };

  getChatList = async () => {
    try {
      // get chat list data
      this.setState({ apiCalledForChatList: true });
      let chatData = await getChatList_api();
      // console.log(chatData);
      this.setState(
        {
          apiCalledForChatList: false,
          chatList: chatData.chats,
        },
        () => {
          this.setTitleUnreadCount();
        }
      );
    } catch (error) {
      this.setState({ apiCalledForChatList: false });
      console.log(error.message);
    }
  };

  setTitleUnreadCount = () => {
    if (this.state.chatList.length > 0) {
      let chatListArray = [...this.state.chatList];
      let unreadCount = chatListArray.reduce(
        (total, obj) => obj.unreadCount + total,
        0
      );
      this.setState({
        unreadCountForTitle: unreadCount,
      });
    }
  };

  handlePresenceStanzas = (stanza) => {
    const attrs = stanza.attrs;
    if (attrs.from !== this.state.myJID) {
      let extractUsernameFrom = attrs.from.split("@");
      // check the presence type
      if (attrs.type === "unavailable") {
        this.updateTheUserStatus(extractUsernameFrom, false);
      } else if (attrs.type === "subscribed") {
        // console.log("both are subscribed");
        let oldChatList = [...this.state.chatList];
        let findIndex = oldChatList.findIndex(
          (item) => item.toId.username === extractUsernameFrom[0]
        );
        if (findIndex !== -1) {
          (async () => {
            try {
              await bothAreSubscribed_api({
                room_id: oldChatList[findIndex].room_id,
              });
            } catch (error) {
              console.log(error);
            }
          })();
        }
      } else {
        this.updateTheUserStatus(extractUsernameFrom, true);
      }
    }
  };

  updateTheUserStatus = (fromId, flag) => {
    // update user status
    let oldChatList = [...this.state.chatList];
    let findIndex = oldChatList.findIndex(
      (item) => item.toId.username === fromId[0]
    );
    if (findIndex !== -1) {
      oldChatList[findIndex] = {
        ...oldChatList[findIndex],
        toId: {
          ...oldChatList[findIndex].toId,
          isOnline: flag,
        },
      };
      this.setState({ chatList: oldChatList });

      // update thecurrent chat also
      if (this.state.activeChat !== null) {
        this.setState((prevState) => ({
          ...prevState,
          activeChat: {
            ...prevState.activeChat,
            toId: {
              ...prevState.activeChat.toId,
              isOnline: flag,
            },
          },
        }));
      }
    }
  };

  onButtonPress = () => {
    this.props.fetchDashoard();
  };

  handleLogout = async () => {
    try {
      await letUserLogout_api();
    } catch (error) {
      console.log(error);
    }

    storageUtils.unsetUserData();
    storageUtils.unsetUserToken();
    this.props.setUserLogout();
    console.log(this.xmpp.status);
    try {
      await this.xmpp.send(xml("presence", { type: "unavailable" }));
      await this.xmpp.stop();
    } catch (error) {
      console.log(error);
    }
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  onChangeMessageVal = async (e) => {
    this.setState({
      textBoxMessageVal: e.target.value,
    });

    if (this.state.activeChat !== null && this.xmpp.status === "online") {
      try {
        // send typing presence to opponent
        const message = xml(
          "message",
          {
            type: "typing",
            to: `${this.state.activeChat.toId.username}@localhost/localhost`,
          },
          xml("typingState", {}, "typing")
        );
        await this.xmpp.send(message);
      } catch (error) {
        console.log(error);
      }
    }
  };

  onChangeSearchTermVal = (e) => {
    this.setState({
      searchTerm: e.target.value,
    });
  };

  setActiveChat = async (e, chat, index) => {
    if (chat) {
      try {
        // return false if same chat
        if (
          this.state.activeChat !== null &&
          this.state.activeChat.room_id === chat.room_id
        ) {
          return false;
        }

        // get opponent data staus
        let opponentData = await getUserById_api(chat.to_id);

        let oldChatList = [...this.state.chatList];

        oldChatList[index] = {
          ...oldChatList[index],
          unreadCount: 0,
        };

        const chatWindow = document.getElementById("chat-window");
        if (chatWindow)
          chatWindow.removeEventListener("scroll", this.handleScrollTop);

        // get chat list data
        this.setState({ apiCalledForChatDetail: true });
        let chatData = await getChatDetail_api(chat.room_id, {
          page: 1,
        });
        // console.log(chatData);
        const chats = [];
        chatData.chats.forEach((chat) => chats.unshift(chat));

        chat.toId.isShowStatus = opponentData.user.isShowStatus;

        this.setState(
          {
            apiCalledForChatDetail: false,
            chatRoom: chats,
            activeChat: chat,
            chatList: oldChatList,
            userIsTyping: false,
            lastTyping: "",
            page: chatData.chats.length > 0 ? chatData.nextPage : 1,
            noData: chatData.chats.length === 0 ? "noData" : "",
          },
          () => {
            // call unread title func
            this.setTitleUnreadCount();
          }
        );

        try {
          // send readAllMessages presence to opponent
          const message = xml(
            "message",
            {
              type: "ack",
              to: `${chat.toId.username}@localhost/localhost`,
            },
            xml("readAllMessages", {}, "readAllMessages")
          );
          await this.xmpp.send(message);

          // send subscribe stanza if not subscribed
          await this.xmpp.send(
            xml("presence", {
              type: "subscribe",
              to: `${chat.toId.username}@localhost/localhost`,
            })
          );
        } catch (error) {
          console.log(error);
        }

        setTimeout(() => {
          // scroll to end
          this.scroolToBottom();

          const chatWindow = document.getElementById("chat-window");
          chatWindow.addEventListener("scroll", this.handleScrollTop);
        }, 1000);
      } catch (error) {
        this.setState({ apiCalledForChatDetail: false });
        console.log(error);
      }
    }
  };

  scroolToBottom = () => {
    let chatWindow = document.getElementById("chat-window");
    var xH = chatWindow.scrollHeight;
    chatWindow.scrollTo(0, xH);
  };

  sendMessage = async () => {
    // console.log(this.state.textBoxMessageVal);
    // console.log("------------------");
    if (this.state.textBoxMessageVal !== "" && this.state.activeChat !== null) {
      let timeStamp = moment.now();

      try {
        // Sends a chat message to user
        const message = xml(
          "message",
          {
            type: "chat",
            to: `${this.state.activeChat.toId.username}@localhost/localhost`,
          },
          xml("body", {}, this.state.textBoxMessageVal),
          xml("timestamp", {}, timeStamp)
        );
        await this.xmpp.send(message);
      } catch (error) {
        console.log(error);
      }

      // add to chat room array
      const data = {
        room_id: this.state.activeChat.room_id,
        from_id: this.state.activeChat.from_id,
        to_id: this.state.activeChat.to_id,
        fromId: this.state.activeChat.fromId,
        toId: this.state.activeChat.toId,
        type: "text",
        content: this.state.textBoxMessageVal,
        createdAt: timeStamp,
        msgTimestamp: timeStamp,
        status: 0,
        deletedAt: null,
      };

      // console.log(timeStamp);

      this.setState(
        (prevState) => ({
          ...prevState,
          chatRoom: [...prevState.chatRoom, data],
        }),
        () => {
          // send stanza if this is first message
          if (this.state.chatRoom.length === 1) {
            (async () => {
              try {
                const message = xml(
                  "message",
                  {
                    type: "ack",
                    to: `${this.state.activeChat.toId.username}@localhost/localhost`,
                  },
                  xml(
                    "searchResultFoundSuccess",
                    {},
                    "searchResultFoundSuccess"
                  )
                );
                await this.xmpp.send(message);
              } catch (error) {
                console.log(error);
              }
            })();
          }
          this.setState({
            textBoxMessageVal: "",
          });
        }
      );

      // update lastMessage start
      let oldChatList = [...this.state.chatList];
      let findIndexNew = oldChatList.findIndex(
        (item) => item.toId.username === this.state.activeChat.toId.username
      );
      if (findIndexNew !== -1) {
        oldChatList[findIndexNew] = {
          ...oldChatList[findIndexNew],
          lastMessage: {
            ...oldChatList[findIndexNew].lastMessage,
            content: data.content,
            createdAt: moment.utc().format(),
            msgTimestamp: timeStamp,
            status: 0,
            deletedAt: null,
          },
        };
        this.setState({ chatList: oldChatList });
      }
      // update lastMessage end

      // scroll to end
      this.scroolToBottom();

      // add to db
      try {
        await sendMessage_api(data);
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  submitSearchForm = async () => {
    try {
      if (this.state.searchTerm !== "") {
        // get search list data
        this.setState({ apiCalledForChatList: true });
        let serahcData = await searchUser_api({
          searchTerm: this.state.searchTerm,
        });
        // console.log(serahcData);
        this.setState({
          apiCalledForChatList: false,
          searchResult: serahcData.data,
        });
        if (serahcData.data.length === 0) {
          toast.error("No result found!");
        }
      } else {
        this.setState({
          searchResult: [],
        });
      }
    } catch (error) {
      this.setState({ apiCalledForChatList: false });
      console.log(error.message);
    }
  };

  detectEnter = (e, type = "") => {
    if (e.which === 13) {
      if (!e.shiftKey) {
        if (type === "search") {
          this.submitSearchForm();
        } else {
          this.sendMessage();
        }
      }
    }
  };

  clearSearchResult = () => {
    // console.log(chatData);
    this.setState({
      searchTerm: "",
      searchResult: [],
      chatList: [],
    });
    setTimeout(() => {
      // fetch chat list
      this.getChatList();
    }, 500);
  };

  initializeChat = async (e, chat) => {
    if (chat) {
      try {
        // get chat list data
        this.setState({ apiCalledForChatInitialize: true });
        let chatData = await letInitiateChat_api({
          from_id: this.props.userData.id,
          to_id: chat.id,
        });

        // check if user is already in chat
        let oldChatList = [...this.state.chatList];
        let findIndexNew = oldChatList.findIndex(
          (item) => item.toId.username === chat.username
        );
        if (findIndexNew !== -1) {
          toast.warning("chat person is already added into list");
        } else {
          try {
            // send subscribe stanza if not subscribed
            await this.xmpp.send(
              xml("presence", {
                type: "subscribe",
                to: `${chat.username}@localhost/localhost`,
              })
            );
            toast.info(chatData.message);
          } catch (error) {
            console.log(error);
          }
        }

        // console.log(chatData);
        this.setState({
          apiCalledForChatInitialize: false,
          activeChat: null,
          searchTerm: "",
          searchResult: [],
          chatList: [],
        });

        setTimeout(() => {
          // fetch chat list
          this.getChatList();
        }, 1000);
      } catch (error) {
        this.setState({ apiCalledForChatInitialize: false });
        console.log(error);
      }
    }
  };

  letDeleteOwnMessage = async (chatData, index) => {
    // console.log(chatData);
    // console.log(index);

    confirmAlert({
      title: "Delete message?",
      message: "Are you sure to want to do this?",
      buttons: [
        {
          label: "Yes, Delete it",
          onClick: () => {
            this.handleDeleteMyMessage(chatData, index);
          },
        },
        {
          label: "Cancel",
          onClick: () => {},
        },
      ],
    });
  };

  handleDeleteMyMessage = async (chatData, index) => {
    let nowMoment = moment.now();

    // user confirm that delete this message
    let oldChatRoom = [...this.state.chatRoom];
    oldChatRoom[index] = {
      ...oldChatRoom[index],
      deletedAt: nowMoment,
    };

    let oldChatList = [...this.state.chatList];
    let findIndex = oldChatList.findIndex(
      (item) => item.toId.username === chatData.toId.username
    );

    // console.log(parseInt(oldChatList[findIndex].lastMessage.msgTimestamp));
    // console.log(parseInt(chatData.msgTimestamp));

    if (findIndex !== -1) {
      // check if lastMessage is the same that is currently deleted
      if (
        oldChatList[findIndex].lastMessage !== null &&
        parseInt(oldChatList[findIndex].lastMessage.msgTimestamp) ===
          parseInt(chatData.msgTimestamp)
      ) {
        // update lastMessage into chat list
        oldChatList[findIndex] = {
          ...oldChatList[findIndex],
          lastMessage: {
            ...oldChatList[findIndex].lastMessage,
            deletedAt: nowMoment,
          },
        };
      }
    }

    // update the current state to deleted
    this.setState({ chatRoom: oldChatRoom, chatList: oldChatList });

    try {
      // update to our db
      chatMessageDelete_api({
        msgTimestamp: parseInt(chatData.msgTimestamp),
      });
    } catch (error) {
      console.log(error);
    }

    try {
      // send custom ack to delete opponent msg
      const message = xml(
        "message",
        {
          type: "type",
          to: `${chatData.toId.username}@localhost/localhost`,
        },
        xml("deleteOwnMsg", {}, chatData.msgTimestamp)
      );
      await this.xmpp.send(message);
    } catch (error) {
      console.log(error);
    }
  };

  letDeleteOpponentMessage = async (chatData, index) => {
    // console.log(chatData);
    // console.log(index);

    confirmAlert({
      title: "Delete message?",
      message: "Are you sure to want to do this?",
      buttons: [
        {
          label: "Yes, Delete it",
          onClick: () => {
            // update the current state to deleted
            let oldChatRoom = [...this.state.chatRoom];
            oldChatRoom[index] = {
              ...oldChatRoom[index],
              status: 5, // 5 means delete for me
            };

            this.setState({ chatRoom: oldChatRoom });

            let oldChatList = [...this.state.chatList];
            let findIndex = oldChatList.findIndex(
              (item) => item.toId.username === chatData.fromId.username
            );

            // console.log(
            //   parseInt(oldChatList[findIndex].lastMessage.msgTimestamp)
            // );
            // console.log(parseInt(chatData.msgTimestamp));

            if (findIndex !== -1) {
              // check if lastMessage is the same that is currently deleted
              if (
                oldChatList[findIndex].lastMessage !== null &&
                parseInt(oldChatList[findIndex].lastMessage.msgTimestamp) ===
                  parseInt(chatData.msgTimestamp)
              ) {
                // update lastMessage into chat list
                oldChatList[findIndex] = {
                  ...oldChatList[findIndex],
                  lastMessage: {
                    ...oldChatList[findIndex].lastMessage,
                    status: 5,
                  },
                };
                this.setState({ chatList: oldChatList });
              }
            }

            try {
              // update to our db
              chatMessageOpponentDelete_api({
                msgTimestamp: parseInt(chatData.msgTimestamp),
              });
            } catch (error) {
              console.log(error);
            }
          },
        },
        {
          label: "Cancel",
          onClick: () => {},
        },
      ],
    });
  };

  onOpenModal = (e, chat, index) => {
    // console.log(chat, index);
    if (chat) {
      this.setState({
        modalMenuData: chat,
        isOpenModal: true,
        chatIndex: index,
      });
    }
  };

  handleModalSTate = () => {
    this.setState((prevState) => ({
      isOpenModal: !prevState.isOpenModal,
      modalMenuData: null,
      chatIndex: null,
    }));
  };

  markAsUnread = async () => {
    if (this.state.modalMenuData !== null && this.state.chatIndex !== null) {
      try {
        // get chat list data
        this.setState({ apiCalledForMarkAsUnread: true });
        try {
          await letChatMarkAsUnread_api({
            room_id: this.state.modalMenuData.room_id,
            opponentId: this.state.modalMenuData.to_id,
          });
        } catch (error) {
          console.log(error);
        }

        // update unreadcount
        let oldChatList = [...this.state.chatList];
        oldChatList[this.state.chatIndex] = {
          ...oldChatList[this.state.chatIndex],
          unreadCount: 1,
        };

        this.setState({ chatList: oldChatList });

        this.setState(
          {
            apiCalledForMarkAsUnread: false,
          },
          () => {
            this.handleModalSTate();
            // call unread title func
            this.setTitleUnreadCount();
          }
        );
      } catch (error) {
        this.setState({ apiCalledForMarkAsUnread: false });
        console.log(error);
      }
    }
  };

  chatArchive = async () => {
    if (this.state.modalMenuData !== null && this.state.chatIndex !== null) {
      try {
        this.setState({ isOpenModal: false });
        confirmAlert({
          title: "Archive Conversation?",
          message: "Are you sure to want to do this?",
          buttons: [
            {
              label: "Yes, Archive it",
              onClick: () => {
                this.chatArchiveOK();
              },
            },
            {
              label: "Cancel",
              onClick: () => {
                this.setState({ isOpenModal: true });
              },
            },
          ],
        });
      } catch (error) {
        this.setState({ apiCalledForChatArchive: false });
        console.log(error);
      }
    }
  };

  chatArchiveOK = async () => {
    // get chat list data
    this.setState({ apiCalledForChatArchive: true });
    try {
      let chatData = await letsChatArchive_api({
        room_id: this.state.modalMenuData.room_id,
      });

      console.log(this.state.modalMenuData);
      console.log(this.state.chatIndex);
      // update
      let oldChatList = [...this.state.chatList];

      let findIndex = oldChatList.findIndex(
        (item) => item.id === this.state.modalMenuData.id
      );

      if (findIndex !== -1) {
        oldChatList[findIndex] = {
          ...oldChatList[findIndex],
          isChatArchive: true,
        };
        this.setState({ chatList: oldChatList });
        toast.info("1 chat archived");
      }
    } catch (err) {
      console.log(err);
      toast.error(err.errors[0].msg);
    }

    // console.log(chatData);
    this.setState({
      apiCalledForChatArchive: false,
      activeChat: null,
      modalMenuData: null,
      chatIndex: null,
    });
  };

  deleteConversation = async () => {
    if (this.state.modalMenuData !== null && this.state.chatIndex !== null) {
      try {
        this.setState({ isOpenModal: false });
        confirmAlert({
          title: "Delete Conversation?",
          message: "Are you sure to want to do this?",
          buttons: [
            {
              label: "Yes, Delete it",
              onClick: () => {
                this.deleteConversationOK();
              },
            },
            {
              label: "Cancel",
              onClick: () => {
                this.setState({ isOpenModal: true });
              },
            },
          ],
        });
      } catch (error) {
        this.setState({ apiCalledForChatDeleteConversation: false });
        console.log(error);
      }
    }
  };

  deleteConversationOK = async () => {
    // get chat list data
    this.setState({ apiCalledForChatDeleteConversation: true });
    try {
      await letsDeleteConversation_api(this.state.modalMenuData.id);
    } catch (error) {
      console.log(error);
    }

    let oldChatList = [...this.state.chatList];
    let findIndex = oldChatList.findIndex(
      (item) => item.id === this.state.modalMenuData.id
    );
    if (findIndex !== -1) {
      // update
      oldChatList[findIndex] = {
        ...oldChatList[findIndex],
        deletedAt: moment.utc().format(),
      };
      this.setState({ chatList: oldChatList });
    }

    this.setState({
      apiCalledForChatDeleteConversation: false,
      activeChat: null,
      modalMenuData: null,
      chatIndex: null,
    });
  };

  onOpenModalFromHere = (e, chat) => {
    if (chat && !chat.deletedAt && chat.status !== 5) {
      this.setState({
        modalChatRoomData: chat,
        isOpenModalForChatRoom: true,
      });
    }
  };

  handleModalSTateFromHere = () => {
    this.setState((prevState) => ({
      isOpenModalForChatRoom: !prevState.isOpenModalForChatRoom,
      modalChatRoomData: null,
    }));
  };

  markAsUnreadFromHere = async () => {
    if (
      this.state.modalChatRoomData !== null &&
      this.state.activeChat !== null
    ) {
      try {
        // get chat list data
        this.setState({ apiCalledForMarkAsUnreadFromHere: true });
        let chatData = await letChatMarkAsUnreadFromHere_api({
          room_id: this.state.modalChatRoomData.room_id,
          opponentId: this.state.modalChatRoomData.to_id,
          msgTimestamp: this.state.modalChatRoomData.msgTimestamp,
        });

        let oldChatList = [...this.state.chatList];
        let findIndex = oldChatList.findIndex(
          (item) => item.room_id === this.state.activeChat.room_id
        );

        if (chatData.updateCnt > 0 && findIndex !== -1) {
          // update unreadcount
          if (oldChatList[findIndex].unreadCount < chatData.updateCnt) {
            oldChatList[findIndex] = {
              ...oldChatList[findIndex],
              unreadCount: chatData.updateCnt,
            };
            this.setState({ chatList: oldChatList });

            try {
              // send custom ack to unReadFromHere
              await this.xmpp.send(
                xml(
                  "message",
                  {
                    type: "ack",
                    to: `${this.state.activeChat.toId.username}@localhost/localhost`,
                  },
                  xml("unReadFromHere", {}, "unReadFromHere")
                )
              );
            } catch (error) {
              console.log(error);
            }
          }
        }

        // console.log(chatData);
        this.setState(
          {
            apiCalledForMarkAsUnreadFromHere: false,
          },
          () => {
            this.handleModalSTateFromHere();
            // call unread title func
            this.setTitleUnreadCount();
          }
        );
      } catch (error) {
        this.setState({ apiCalledForMarkAsUnreadFromHere: false });
        console.log(error);
      }
    }
  };

  handleVisibilityChange = (isVisible) => {
    this.setState({ isPageVisible: isVisible });
  };

  settingsClick = () => {
    this.setState((prevState) => ({
      isOpenProfileModal: !prevState.isOpenProfileModal,
      full_name: this.props.userData.full_name,
      avatar: "",
      isCheckedPushCheckbox: this.props.userData.isNotification,
      isCheckedShowStatusCheckBox: this.props.userData.isShowStatus,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    }));
  };

  handleImageClick = () => {
    document.getElementById("imgupload").click();
  };

  handleImageChangeProfile = async (e) => {
    let input = e.target;
    if (input.files && input.files[0]) {
      try {
        const formData = new FormData();
        formData.append("media", input.files[0]);

        this.setState({ apiCaleedForUpdateSetting: true });
        let apiData = await letsUploadSingleImage_api(formData);
        // console.log(apiData);
        this.setState({
          apiCaleedForUpdateSetting: false,
          avatar: apiData.data.path,
        });

        var reader = new FileReader();
        reader.onload = function (e) {
          document.getElementById("profileImage").src = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      } catch (err) {
        console.log(err);
        this.setState({
          apiCaleedForUpdateSetting: false,
        });
        toast.error(err.errors[0].msg);
      }
    }
  };

  onProfileChangeHandler = async (e) => {
    e.preventDefault();
    if (this.state.full_name !== "") {
      try {
        this.setState({ apiCaleedForUpdateSetting: true });
        let apiParams = {
          full_name: this.state.full_name,
        };
        if (this.state.avatar !== "") apiParams.avatar = this.state.avatar;
        let apiData = await updateProfile_api(apiParams);
        // console.log(apiData);
        // Set token and login to app.
        storageUtils.setUserData(apiData.user);
        // user register success
        this.props.setUserDataSuccess(apiData);
        this.setState({
          apiCaleedForUpdateSetting: false,
          avatar: "",
        });
        toast.success(apiData.message);
      } catch (err) {
        console.log(err);
        this.setState({
          apiCaleedForUpdateSetting: false,
        });
        toast.error(err.errors[0].msg);
      }
    }
  };

  handlePushCheckChange = async (e) => {
    // console.log(e.target.checked);
    this.setState((prevState) => ({
      isCheckedPushCheckbox: !prevState.isCheckedPushCheckbox,
    }));

    try {
      this.setState({ apiCaleedForUpdateisNotification: true });
      let apiParams = {
        isNotification: e.target.checked,
      };
      let apiData = await updateIsNotification_api(apiParams);
      // console.log(apiData);
      // Set token and login to app.
      storageUtils.setUserData(apiData.user);
      // user register success
      this.props.setUserDataSuccess(apiData);
      this.setState({
        apiCaleedForUpdateisNotification: false,
      });
      toast.success("Setting has been updated succesfully");
    } catch (err) {
      console.log(err);
      this.setState({
        apiCaleedForUpdateisNotification: false,
      });
      toast.error(err.errors[0].msg);
    }
  };

  handleShowStatusCheckChange = async (e) => {
    // console.log(e.target.checked);
    this.setState((prevState) => ({
      isCheckedShowStatusCheckBox: !prevState.isCheckedShowStatusCheckBox,
    }));

    try {
      this.setState({ apiCaleedForUpdateShawStatus: true });
      let apiParams = {
        isShowStatus: e.target.checked,
      };
      let apiData = await updateShowStatus_api(apiParams);
      // console.log(apiData);
      // Set token and login to app.
      storageUtils.setUserData(apiData.user);
      // user register success
      this.props.setUserDataSuccess(apiData);
      this.setState({
        apiCaleedForUpdateShawStatus: false,
      });
      toast.success("Setting has been updated succesfully");
    } catch (err) {
      console.log(err);
      this.setState({
        apiCaleedForUpdateShawStatus: false,
      });
      toast.error(err.errors[0].msg);
    }
  };

  handleChangePasswordSubmit = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      if (this.state.newPassword !== this.state.confirmPassword) {
        toast.error("Password and confirm password mismatched!!!");
        return false;
      }

      try {
        this.setState({ apiCaleedForUpdateSetting: true });
        let apiData = await changePassword_api({
          oldPassword: this.state.oldPassword,
          newPassword: this.state.newPassword,
        });
        // console.log("apiData ", apiData);
        this.setState({
          apiCaleedForUpdateSetting: false,
        });
        toast.success(apiData.message);
        this.settingsClick();
      } catch (err) {
        console.log(err);
        this.setState({
          apiCaleedForUpdateSetting: false,
        });
        toast.error(err.errors[0].msg);
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };

  render() {
    const profileImgBaseUrl =
      process.env.NODE_ENV === "development"
        ? "http://localhost:2020/"
        : "http://5.189.134.24:2020/";

    const { unreadCountForTitle } = this.state;

    return (
      <PageVisibility onChange={this.handleVisibilityChange}>
        <>
          <Helmet>
            <title>
              {unreadCountForTitle > 0 ? `(${unreadCountForTitle}) ` : ""} Web
              Chat
            </title>
          </Helmet>
          <DashboardView
            {...this.props}
            onButtonPress={this.onButtonPress}
            handleLogout={this.handleLogout}
            onChangeMessageVal={this.onChangeMessageVal}
            setActiveChat={this.setActiveChat}
            sendMessage={this.sendMessage}
            onChangeSearchTermVal={this.onChangeSearchTermVal}
            submitSearchForm={this.submitSearchForm}
            initializeChat={this.initializeChat}
            clearSearchResult={this.clearSearchResult}
            detectEnter={this.detectEnter}
            letDeleteOwnMessage={this.letDeleteOwnMessage}
            letDeleteOpponentMessage={this.letDeleteOpponentMessage}
            onOpenModal={this.onOpenModal}
            onOpenModalFromHere={this.onOpenModalFromHere}
            settingsClick={this.settingsClick}
            {...this.state}
          />

          {/* modal for chatlist options */}
          <Modal
            open={this.state.isOpenModal}
            onClose={this.handleModalSTate}
            center
            showCloseIcon={false}
          >
            <h2>Select action</h2>
            <ul
              className="list-group"
              style={{
                cursor: "pointer",
              }}
            >
              {this.state.modalMenuData &&
                this.state.modalMenuData.unreadCount === 0 && (
                  <li
                    className={`list-group-item ${
                      this.state.apiCalledForMarkAsUnread ? "disabled" : ""
                    }`}
                    onClick={this.markAsUnread}
                  >
                    Mark as unread
                    {this.state.apiCalledForMarkAsUnread && (
                      <i className="fa fa-spinner ml-3 fa-pulse " />
                    )}
                  </li>
                )}
              {this.state.modalMenuData && (
                <li
                  className={`list-group-item ${
                    this.state.apiCalledForChatArchive ? "disabled" : ""
                  }`}
                  onClick={this.chatArchive}
                >
                  Archive
                  {this.state.apiCalledForChatArchive && (
                    <i className="fa fa-spinner ml-3 fa-pulse " />
                  )}
                </li>
              )}
              {this.state.modalMenuData && (
                <li
                  className={`list-group-item ${
                    this.state.apiCalledForChatDeleteConversation
                      ? "disabled"
                      : ""
                  }`}
                  onClick={this.deleteConversation}
                >
                  Delete conversation
                  {this.state.apiCalledForChatDeleteConversation && (
                    <i className="fa fa-spinner ml-3 fa-pulse " />
                  )}
                </li>
              )}
            </ul>
          </Modal>

          {/* modal for chatRoom option */}
          <Modal
            open={this.state.isOpenModalForChatRoom}
            onClose={this.handleModalSTateFromHere}
            center
            showCloseIcon={false}
          >
            <h2>Select action</h2>
            <ul
              className="list-group"
              style={{
                cursor: "pointer",
              }}
            >
              {this.state.modalChatRoomData !== null && (
                <li
                  className={`list-group-item ${
                    this.state.apiCalledForMarkAsUnreadFromHere
                      ? "disabled"
                      : ""
                  }`}
                  onClick={this.markAsUnreadFromHere}
                >
                  Unread from here
                  {this.state.apiCalledForMarkAsUnreadFromHere && (
                    <i className="fa fa-spinner ml-3 fa-pulse " />
                  )}
                </li>
              )}
            </ul>
          </Modal>

          {/* Modal for profile and settings */}
          <Modal
            open={this.state.isOpenProfileModal}
            onClose={this.settingsClick}
            center
            showCloseIcon={false}
          >
            <div
              className="modal-body"
              style={{
                width: "600px",
              }}
            >
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    borderRight: "1px solid rgba(0,0,0,.1)",
                  }}
                >
                  <div
                    className="nav flex-column nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <a
                      className="nav-link active"
                      id="v-pills-profile-tab"
                      data-toggle="pill"
                      href="#v-pills-profile"
                      role="tab"
                      aria-controls="v-pills-profile"
                      aria-selected="false"
                    >
                      Profile
                    </a>
                    <a
                      className="nav-link"
                      id="v-pills-settings-tab"
                      data-toggle="pill"
                      href="#v-pills-settings"
                      role="tab"
                      aria-controls="v-pills-settings"
                      aria-selected="false"
                    >
                      Settings
                    </a>
                    <a
                      className="nav-link"
                      id="v-pills-home-tab"
                      data-toggle="pill"
                      href="#v-pills-home"
                      role="tab"
                      aria-controls="v-pills-home"
                      aria-selected="true"
                    >
                      Change Password
                    </a>
                  </div>
                </div>
                <div className="col-8">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-profile"
                      role="tabpanel"
                      aria-labelledby="v-pills-profile-tab"
                    >
                      <center>
                        <a>
                          <img
                            src={
                              this.props.userData.avatar
                                ? profileImgBaseUrl + this.props.userData.avatar
                                : "https://i.ibb.co/thJsWNJ/default-avatar.png"
                            }
                            name="aboutme"
                            width="140"
                            height="140"
                            border="0"
                            className="img-circle c-pointer"
                            style={{
                              borderRadius: "50%",
                            }}
                            id="profileImage"
                            onClick={this.handleImageClick}
                          />
                        </a>
                        <h3 className="media-heading">
                          {this.props.userData.full_name}
                        </h3>
                      </center>
                      <hr />
                      <center>
                        <div className="text-left">
                          <form
                            method="post"
                            onSubmit={this.onProfileChangeHandler}
                          >
                            <div className="form-group">
                              <label
                                htmlFor="recipient-username"
                                className="col-form-label"
                              >
                                Username:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="recipient-username"
                                name="username"
                                readOnly
                                defaultValue={this.props.userData.username}
                              />
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="recipient-email"
                                className="col-form-label"
                              >
                                Email Address:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="recipient-email"
                                name="email"
                                readOnly
                                defaultValue={this.props.userData.email}
                              />
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="recipient-name"
                                className="col-form-label"
                              >
                                Full Name:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="recipient-name"
                                name="full_name"
                                value={this.state.full_name}
                                onChange={(e) => {
                                  this.setState({ full_name: e.target.value });
                                }}
                              />
                              <input
                                type="file"
                                id="imgupload"
                                className="d-none"
                                onChange={this.handleImageChangeProfile}
                              />
                            </div>
                            <div className="form-group">
                              <button
                                className="btn btn-primary"
                                disabled={this.state.apiCaleedForUpdateSetting}
                              >
                                Save
                                {this.state.apiCaleedForUpdateSetting && (
                                  <i className="fa fa-spinner ml-3 fa-pulse " />
                                )}
                              </button>
                            </div>
                          </form>
                        </div>
                      </center>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-settings"
                      role="tabpanel"
                      aria-labelledby="v-pills-settings-tab"
                    >
                      <div className="form-row">
                        <input
                          type="checkbox"
                          className="form-control mt-2"
                          id="customSwitches"
                          style={{ width: "auto" }}
                          checked={this.state.isCheckedPushCheckbox}
                          onChange={(e) => this.handlePushCheckChange(e)}
                        />
                        &nbsp;&nbsp;
                        <label
                          className="control-label mt-1"
                          htmlFor="customSwitches"
                        >
                          Enable push notification
                          {this.state.apiCaleedForUpdateisNotification && (
                            <i className="fa fa-spinner ml-3 fa-pulse " />
                          )}
                        </label>
                      </div>
                      <div className="form-row mt-2">
                        <input
                          type="checkbox"
                          className="form-control mt-2"
                          id="isShowStatus"
                          style={{ width: "auto" }}
                          checked={this.state.isCheckedShowStatusCheckBox}
                          onChange={(e) => this.handleShowStatusCheckChange(e)}
                        />
                        &nbsp;&nbsp;
                        <label
                          className="control-label mt-1"
                          htmlFor="isShowStatus"
                        >
                          Show status
                          {this.state.apiCaleedForUpdateShawStatus && (
                            <i className="fa fa-spinner ml-3 fa-pulse " />
                          )}
                        </label>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <div className="text-left">
                        <form
                          method="post"
                          onSubmit={this.handleChangePasswordSubmit}
                        >
                          <div className="form-group">
                            <label
                              htmlFor="recipient-oldPassword"
                              className="col-form-label"
                            >
                              Old password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="recipient-oldPassword"
                              name="oldPassword"
                              value={this.state.oldPassword}
                              onChange={(e) => {
                                this.setState({ oldPassword: e.target.value });
                              }}
                            />
                            {this.validator.message(
                              "oldPassword",
                              this.state.oldPassword,
                              "required|alpha_num|min:6|max:14",
                              { className: "text-danger" }
                            )}
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor="recipient-newPassword"
                              className="col-form-label"
                            >
                              New password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="recipient-newPassword"
                              name="newPassword"
                              value={this.state.newPassword}
                              onChange={(e) => {
                                this.setState({ newPassword: e.target.value });
                              }}
                            />
                            {this.validator.message(
                              "newPassword",
                              this.state.newPassword,
                              "required|alpha_num|min:6|max:14",
                              { className: "text-danger" }
                            )}
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor="recipient-confirmPassword"
                              className="col-form-label"
                            >
                              Confirm password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="recipient-confirmPassword"
                              name="confirmPassword"
                              value={this.state.confirmPassword}
                              onChange={(e) => {
                                this.setState({
                                  confirmPassword: e.target.value,
                                });
                              }}
                            />
                            {this.validator.message(
                              "confirmPassword",
                              this.state.confirmPassword,
                              "required|alpha_num|min:6|max:14",
                              { className: "text-danger" }
                            )}
                          </div>
                          <div className="form-group">
                            <button
                              className="btn btn-primary"
                              disabled={this.state.apiCaleedForUpdateSetting}
                            >
                              Update
                              {this.state.apiCaleedForUpdateSetting && (
                                <i className="fa fa-spinner ml-3 fa-pulse " />
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </>
      </PageVisibility>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth,
  userData: state.auth.user,
  dashboard: state.dashboard,
  isLoading: state.dashboard.isLoading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDashoard: () => dispatch(dashboardActions.fetchDashoard()),
    setUserLogout: () => dispatch(setUserLogout()),
    setUserDataSuccess: (data) => dispatch(setUserDataSuccess(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
