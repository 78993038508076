import Api from "./index";
import { getUserToken } from "../utils/storageUtils";
import { convertObjectToQuerystring } from "../utils/helper";

export const checkExist_api = (data) => {
  const token = getUserToken();
  return Api(`/auth/check/exists`, data, "POST", token);
};

export const userLogin_api = (data) => {
  const token = getUserToken();
  return Api(`/auth/login`, data, "POST", token);
};

export const userRegister_api = (data) => {
  const token = getUserToken();
  return Api(`/auth/register`, data, "POST", token);
};

export const forgotPassword_api = (data) => {
  const token = getUserToken();
  return Api(`/auth/requestOTP`, data, "POST", token);
};

export const getMe_api = () => {
  const token = getUserToken();
  return Api(`/user/me`, null, "GET", token);
};

export const getUserById_api = (user_id) => {
  const token = getUserToken();
  return Api(`/user/info/${user_id}`, null, "GET", token);
};

export const letUserLogout_api = () => {
  const token = getUserToken();
  return Api(`/user/logout`, null, "GET", token);
};

export const getChatList_api = (data) => {
  const token = getUserToken();
  return Api(`/user/chats`, data, "GET", token);
};

export const getChatDetail_api = (room_id, filters) => {
  const token = getUserToken();
  let filter = `${convertObjectToQuerystring(filters)}`;
  return Api(`/user/chats/${room_id}?${filter}`, null, "GET", token);
};

export const sendMessage_api = (data) => {
  const token = getUserToken();
  return Api(`/user/chat/send`, data, "POST", token);
};

export const searchUser_api = (data) => {
  const token = getUserToken();
  return Api(`/user/search`, data, "POST", token);
};

export const letInitiateChat_api = (data) => {
  const token = getUserToken();
  return Api(`/user/initiate/chat`, data, "POST", token);
};

export const manageChatRoom_api = (data) => {
  const token = getUserToken();
  return Api(`/user/chat/manage/room`, data, "POST", token);
};

export const chatUpdateStatus_api = (data) => {
  const token = getUserToken();
  return Api(`/user/chat/update/status`, data, "POST", token);
};

export const chatMessageDelete_api = (data) => {
  const token = getUserToken();
  return Api(`/user/chat/message/remove`, data, "POST", token);
};

export const chatMessageOpponentDelete_api = (data) => {
  const token = getUserToken();
  return Api(`/user/chat/message/opponent/remove`, data, "POST", token);
};

export const letChatMarkAsUnread_api = (data) => {
  const token = getUserToken();
  return Api(`/user/chat/list/markAsUnread`, data, "POST", token);
};

export const letChatMarkAsUnreadFromHere_api = (data) => {
  const token = getUserToken();
  return Api(`/user/chat/room/markAsUnreadFromHere`, data, "POST", token);
};

export const bothAreSubscribed_api = (data) => {
  const token = getUserToken();
  return Api(`/user/chat/subscribed`, data, "POST", token);
};

export const letsChatArchive_api = (data) => {
  const token = getUserToken();
  return Api(`/user/chat/archive`, data, "POST", token);
};

export const letsDeleteConversation_api = (chat_id) => {
  const token = getUserToken();
  return Api(`/user/chats/${chat_id}`, null, "DELETE", token);
};

export const letsUploadSingleImage_api = (data) => {
  const token = getUserToken();
  return Api(`/media/single`, data, "POST", token, null, false);
};

export const updateProfile_api = (data) => {
  const token = getUserToken();
  return Api(`/user/updateProfile`, data, "POST", token, null);
};

export const updateIsNotification_api = (data) => {
  const token = getUserToken();
  return Api(`/user/updateIsNotification`, data, "POST", token, null);
};

export const updateShowStatus_api = (data) => {
  const token = getUserToken();
  return Api(`/user/updateShowStatus`, data, "POST", token, null);
};

export const changePassword_api = (data) => {
  const token = getUserToken();
  return Api(`/user/changePassword`, data, "POST", token, null);
};
