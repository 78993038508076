import React, { Component } from "react";
import "./index.css";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import singleGrey from "../../../../single_grey.png";
import dblGrey from "../../../../double_grey.png";
import dblBlue from "../../../../double_blue.png";

// status list

// 0 - sent
// 1 - delivered
// 2 - read
// 3 - failed
// 4 - deleted
// 5 - deleted for me, opponent messages

export class DashboardView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
    };
  }

  static propTypes = {};

  render() {
    const {
      chatList,
      apiCalledForChatList,
      activeChat,
      searchTerm,
      searchResult,
      apiCalledForChatDetail,
      apiCalledForChatInitialize,
      chatRoom,
    } = this.props;

    let isLeft, statusToShow, dayToDisplay;

    var name = this.props.userData.full_name;
    var initials = name.match(/\b\w/g) || [];
    let myName = (
      (initials.shift() || "") + (initials.pop() || "")
    ).toUpperCase();

    let receiverName = "";
    if (activeChat !== null) {
      name = activeChat.toId.full_name;
      initials = name.match(/\b\w/g) || [];
      receiverName = (
        (initials.shift() || "") + (initials.pop() || "")
      ).toUpperCase();
    }

    const profileImgBaseUrl =
      process.env.NODE_ENV === "development"
        ? "http://localhost:2020/"
        : "http://5.189.134.24:2020/";

    return (
      <div className="container">
        {/* Page header start */}
        <div className="page-title">
          <div className="row gutters">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 pr-0">
              <ul className="users">
                <li
                  className="person d-flex justify-content-between pr-0"
                  data-chat="person1"
                >
                  <div className="user">
                    <img
                      src={
                        this.props.userData.avatar
                          ? profileImgBaseUrl + this.props.userData.avatar
                          : "https://i.ibb.co/thJsWNJ/default-avatar.png"
                      }
                      alt="default-avatar"
                      border="0"
                    />
                    <span className="status online" />
                  </div>
                  <p className="name-time pt-3">
                    <span className="name">
                      {this.props.userData.full_name}
                      &nbsp;&nbsp;
                      <i
                        className="fa fa-cog"
                        aria-hidden="true"
                        onClick={this.props.settingsClick}
                      ></i>
                    </span>
                    <span
                      className="pull-right"
                      style={{
                        marginLeft: "150px",
                      }}
                      onClick={() => this.props.handleLogout()}
                      title="logout"
                    >
                      <i className="fa fa-sign-out" aria-hidden="true"></i>
                    </span>
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
              <div className="chat-search-box">
                <div className="input-group">
                  <input
                    className="form-control"
                    placeholder="Search users to start conversation"
                    value={searchTerm}
                    onChange={(e) => this.props.onChangeSearchTermVal(e)}
                    onKeyPress={(e) => this.props.detectEnter(e, "search")}
                  />
                  <div className="input-group-btn">
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => this.props.submitSearchForm()}
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Page header end */}
        {/* Content wrapper start */}
        <div className="content-wrapper">
          {/* Row start */}
          <div className="row gutters">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="card m-0">
                {/* Row start */}
                <div className="row no-gutters">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-3">
                    <div className="users-container p-0">
                      <div
                        className="selected-user p-4"
                        style={{
                          lineHeight: "unset",
                        }}
                      >
                        <div className="d-flex justify-content-between">
                          <span className="name">
                            {searchResult.length === 0
                              ? "All Conversation"
                              : "Search Result"}
                          </span>
                          {searchResult.length > 0 && (
                            <span
                              className="text-muted"
                              style={{
                                fontSize: "14px",
                                cursor: "pointer",
                              }}
                              title="clear search result"
                              onClick={() => this.props.clearSearchResult()}
                            >
                              &nbsp;Clear
                            </span>
                          )}
                        </div>
                        {searchResult.length > 0 && (
                          <span
                            className="text-muted"
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            Select user to start conversation
                          </span>
                        )}
                      </div>
                      <ul className="users leftUsersList">
                        {chatList.length > 0 &&
                        !apiCalledForChatList &&
                        !apiCalledForChatInitialize &&
                        searchResult.length === 0
                          ? chatList
                              .filter(
                                (item) =>
                                  item.isChatArchive === false &&
                                  item.deletedAt === null
                              )
                              .map((chat, index) => {
                                return (
                                  <li
                                    className={`person ${
                                      activeChat &&
                                      activeChat.room_id === chat.room_id
                                        ? "active-user"
                                        : ""
                                    }`}
                                    data-chat="person1"
                                    key={index}
                                  >
                                    <div className="user">
                                      <img
                                        // src="https://www.bootdey.com/img/Content/avatar/avatar3.png"
                                        src={
                                          chat.toId.avatar
                                            ? profileImgBaseUrl +
                                              chat.toId.avatar
                                            : "https://i.ibb.co/thJsWNJ/default-avatar.png"
                                        }
                                        alt={chat.toId.full_name}
                                        onClick={(e) =>
                                          this.props.setActiveChat(
                                            e,
                                            chat,
                                            index
                                          )
                                        }
                                      />
                                      <span
                                        className={`status ${
                                          chat.toId.isOnline &&
                                          chat.toId.isShowStatus
                                            ? "online"
                                            : "offline"
                                        }`}
                                      />
                                    </div>
                                    <p className="name-time pl-3 mb-0">
                                      <span
                                        className="name"
                                        onClick={(e) =>
                                          this.props.setActiveChat(
                                            e,
                                            chat,
                                            index
                                          )
                                        }
                                      >
                                        {chat.toId.full_name}
                                      </span>
                                      {"  "}
                                      {chat.unreadCount > 0 && (
                                        <span className="badge badge-secondary">
                                          {chat.unreadCount}
                                        </span>
                                      )}
                                      <span
                                        className="threedots ml-3"
                                        onClick={(e) =>
                                          this.props.onOpenModal(e, chat, index)
                                        }
                                      />
                                      <br />
                                      {((chat.lastMessage &&
                                        chat.lastMessage.deletedAt === null &&
                                        chat.lastMessage.status !== 5) ||
                                        (chat.lastMessage &&
                                          chat.lastMessage.from_id ===
                                            this.props.userData.id &&
                                          chat.lastMessage.deletedAt ===
                                            null)) && (
                                        <span
                                          onClick={(e) =>
                                            this.props.setActiveChat(
                                              e,
                                              chat,
                                              index
                                            )
                                          }
                                        >
                                          <span className="text-muted">
                                            {chat.lastMessage.content.substr(
                                              0,
                                              20
                                            )}
                                            {chat.lastMessage.content.length >
                                            20
                                              ? "..."
                                              : ""}
                                          </span>
                                          <span className="time ml-3">
                                            {moment
                                              .utc(chat.lastMessage.createdAt)
                                              .local()
                                              .fromNow(true)}
                                          </span>
                                        </span>
                                      )}
                                    </p>
                                  </li>
                                );
                              })
                          : null}
                        {apiCalledForChatList ||
                          (apiCalledForChatInitialize && (
                            <div className="d-flex justify-content-between">
                              <i className="fa fa-spinner ml-3 fa-pulse fa-2x" />
                            </div>
                          ))}
                        {/* search results start */}
                        {!apiCalledForChatList &&
                        !apiCalledForChatInitialize &&
                        searchResult.length > 0
                          ? searchResult.map((resultItem, index) => {
                              return (
                                <li
                                  className={`person`}
                                  data-chat="person1"
                                  key={index}
                                  onClick={(e) =>
                                    this.props.initializeChat(e, resultItem)
                                  }
                                >
                                  <div className="user">
                                    <img
                                      src={
                                        resultItem.avatar
                                          ? profileImgBaseUrl +
                                            resultItem.avatar
                                          : "https://i.ibb.co/thJsWNJ/default-avatar.png"
                                      }
                                      alt={resultItem.full_name}
                                    />
                                    {/* <span className="status online" /> */}
                                  </div>
                                  <p className="name-time">
                                    <span className="name">
                                      {resultItem.full_name}
                                    </span>
                                  </p>
                                </li>
                              );
                            })
                          : null}
                        {/* search results end */}
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-9 col-9">
                    {activeChat !== null && searchResult.length === 0 ? (
                      <>
                        <div
                          className="selected-user pt-2 row"
                          style={{
                            lineHeight: "unset",
                          }}
                        >
                          <div className="user ml-2">
                            <img
                              src={
                                activeChat.toId.avatar
                                  ? profileImgBaseUrl + activeChat.toId.avatar
                                  : "https://i.ibb.co/thJsWNJ/default-avatar.png"
                              }
                              alt={activeChat.toId.full_name}
                              style={{
                                width: "48px",
                                height: "48px",
                                borderRadius: "30px",
                              }}
                            />
                          </div>
                          <div className="ml-2">
                            <span className="name">
                              {activeChat.toId.full_name}
                              {this.props.userIsTyping && (
                                <>
                                  <span
                                    className="text-muted ml-3"
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    Typing...
                                  </span>
                                </>
                              )}
                            </span>
                            <br />
                            <span
                              className="text-muted"
                              style={{
                                fontSize: "14px",
                              }}
                              title="clear search result"
                            >
                              {activeChat.toId.isOnline &&
                              activeChat.toId.isShowStatus
                                ? "online"
                                : "offline"}
                            </span>
                          </div>
                        </div>
                        <div className="chat-container">
                          <ul
                            className="chat-box chatContainerScroll pr-2"
                            id="chat-window"
                          >
                            {apiCalledForChatDetail && (
                              <div className="text-center">
                                <i className="fa fa-spinner ml-3 fa-pulse " />
                              </div>
                            )}
                            {chatRoom.length > 0 &&
                              chatRoom.map((chatData, index) => {
                                isLeft =
                                  chatData.from_id !== this.props.userData.id;

                                if (chatData.status === 1) {
                                  statusToShow = dblGrey;
                                } else if (
                                  chatData.status === 2 ||
                                  chatData.status === 5
                                ) {
                                  statusToShow = dblBlue;
                                } else {
                                  statusToShow = singleGrey;
                                }

                                if (
                                  index === 0 ||
                                  (index > 0 &&
                                    moment
                                      .utc(chatRoom[index].createdAt)
                                      .local()
                                      .format("DD MMMM YYYY") !==
                                      moment
                                        .utc(chatRoom[index - 1].createdAt)
                                        .local()
                                        .format("DD MMMM YYYY"))
                                ) {
                                  dayToDisplay = (
                                    <div className="csLine mt-3">
                                      <h2>
                                        <span>
                                          {moment
                                            .utc(chatRoom[index].createdAt)
                                            .local()
                                            .format("DD MMMM YYYY")}
                                        </span>
                                      </h2>
                                    </div>
                                  );
                                } else {
                                  dayToDisplay = "";
                                }

                                return (
                                  <div key={"chat-" + index}>
                                    {dayToDisplay}
                                    {isLeft ? (
                                      <li className="chat-left">
                                        <div className="chat-avatar">
                                          <img
                                            src={
                                              activeChat.toId.avatar
                                                ? profileImgBaseUrl +
                                                  activeChat.toId.avatar
                                                : "https://i.ibb.co/thJsWNJ/default-avatar.png"
                                            }
                                            alt={receiverName}
                                          />
                                          <div className="chat-name">
                                            {receiverName}
                                          </div>
                                        </div>
                                        <div
                                          className="chat-text"
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) =>
                                            this.props.onOpenModalFromHere(
                                              e,
                                              chatData,
                                              index
                                            )
                                          }
                                        >
                                          {chatData.deletedAt ||
                                          chatData.status === 5 ? (
                                            <i
                                              style={{
                                                fontSize: "14px",
                                              }}
                                            >
                                              <span
                                                className="fa fa-ban"
                                                aria-hidden="true"
                                              ></span>{" "}
                                              &nbsp; This message was deleted
                                            </i>
                                          ) : (
                                            chatData.content
                                          )}
                                        </div>
                                        <div className="chat-hour">
                                          {moment
                                            .utc(chatData.createdAt)
                                            .local()
                                            .format("HH:mm")}
                                          &nbsp;
                                          {!chatData.deletedAt &&
                                            chatData.status !== 5 && (
                                              <>
                                                &nbsp;
                                                <span
                                                  style={{
                                                    fontSize: "16px",
                                                    color: "grey",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    this.props.letDeleteOpponentMessage(
                                                      chatData,
                                                      index
                                                    )
                                                  }
                                                  title="Delete message"
                                                >
                                                  <i className="fa fa-trash"></i>
                                                </span>
                                              </>
                                            )}
                                        </div>
                                      </li>
                                    ) : (
                                      <li className="chat-right">
                                        <div className="chat-hour">
                                          {moment
                                            .utc(chatData.createdAt)
                                            .local()
                                            .format("HH:mm")}
                                          &nbsp;
                                          <span>
                                            <img
                                              src={statusToShow}
                                              style={{
                                                height: "16px",
                                                width: "16px",
                                              }}
                                              alt="status"
                                            />
                                          </span>
                                          {!chatData.deletedAt && (
                                            <>
                                              &nbsp;
                                              <span
                                                style={{
                                                  fontSize: "16px",
                                                  color: "grey",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  this.props.letDeleteOwnMessage(
                                                    chatData,
                                                    index
                                                  )
                                                }
                                                title="Delete message"
                                              >
                                                <i className="fa fa-trash"></i>
                                              </span>
                                            </>
                                          )}
                                        </div>
                                        <div className="chat-text">
                                          {chatData.deletedAt ? (
                                            <i style={{ fontSize: "14px" }}>
                                              <span
                                                className="fa fa-ban"
                                                aria-hidden="true"
                                              ></span>
                                              &nbsp; This message was deleted
                                            </i>
                                          ) : (
                                            chatData.content
                                          )}
                                        </div>
                                        <div className="chat-avatar">
                                          <img
                                            src={
                                              this.props.userData.avatar
                                                ? profileImgBaseUrl +
                                                  this.props.userData.avatar
                                                : "https://i.ibb.co/thJsWNJ/default-avatar.png"
                                            }
                                            alt={myName}
                                          />
                                          <div className="chat-name">
                                            {myName}
                                          </div>
                                        </div>
                                      </li>
                                    )}
                                  </div>
                                );
                              })}
                          </ul>
                          <div className="form-group mt-3 mb-0">
                            <div className="input-group">
                              <input
                                className="form-control"
                                placeholder="Type your message here..."
                                value={this.props.textBoxMessageVal}
                                onChange={(e) =>
                                  this.props.onChangeMessageVal(e)
                                }
                                onKeyPress={(e) => this.props.detectEnter(e)}
                              />
                              <div className="input-group-btn">
                                <button
                                  type="button"
                                  className="btn btn-info"
                                  onClick={() =>
                                    this.props.sendMessage(this.state.message)
                                  }
                                >
                                  <i className="fa fa-paper-plane-o" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="text-center">
                        <p
                          style={{
                            padding: "30%",
                          }}
                        >
                          Start chat with user
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                {/* Row end */}
              </div>
            </div>
          </div>
          {/* Row end */}
        </div>
        {/* Content wrapper end */}

        <ToastContainer />
      </div>
    );
  }
}

export default DashboardView;
