import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  REGISTER_USER_SUCCESS,
  LOGOUT_USER_SUCCESS,
  SET_USERDATA_SUCCESS,
} from "../actions";

const INIT_STATE = {
  user:
    JSON.parse(localStorage.getItem("userData")) !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : null,
  loading: false,
  type: "",
  isLoggedIn: localStorage.getItem("token") !== null ? true : false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        type: action.type,
        isLoggedIn: true,
      };
    case LOGIN_USER_FAILED:
      return {
        ...state,
        loading: false,
        user: action.payload,
        type: action.type,
        isLoggedIn: false,
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        type: action.type,
        isLoggedIn: true,
      };
    case SET_USERDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        type: action.type,
        isLoggedIn: true,
      };
    case LOGOUT_USER_SUCCESS:
      return INIT_STATE;
    default:
      return { ...state };
  }
};
