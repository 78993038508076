import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  REGISTER_USER_SUCCESS,
  REGISTER_USER,
  LOGOUT_USER_SUCCESS,
  SET_USERDATA_SUCCESS,
} from "../actions";

export const setUserLogout = () => ({
  type: LOGOUT_USER_SUCCESS,
});

export const loginUser = (user = null) => ({
  type: LOGIN_USER,
  payload: { user },
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});
export const loginUserFailed = (user) => ({
  type: LOGIN_USER_FAILED,
  payload: user,
});

export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});
export const registerUser = (user) => ({
  type: REGISTER_USER,
  payload: user,
});

export const setUserDataSuccess = (user) => ({
  type: SET_USERDATA_SUCCESS,
  payload: user,
});
