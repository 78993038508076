import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import "../../App.css";
import "./index.css";
import SimpleReactValidator from "simple-react-validator";
import { loginUserSuccess } from "../../actions";
import { userLogin_api } from "../../api/auth";
import * as storageUtils from "../../utils/storageUtils";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      errors: [],
      isAPiCalled: false,
      xmpp: null,
    };

    this.validator = new SimpleReactValidator();
  }

  handleLoginClick = () => {
    // this.props.loginUser();
  };

  setStateFromInput = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      try {
        this.setState({ isAPiCalled: true, errors: [] });
        let apiData = await userLogin_api(this.state);
        // console.log("apiData ", apiData);
        this.setState({ isAPiCalled: false });
        // Set token and login to app.
        storageUtils.setUserData(apiData.user);
        storageUtils.setUserToken(apiData.token);
        // user login success
        this.props.loginUserSuccess(apiData);
        // redirect to homepage
        setTimeout(() => {
          this.props.history.push({
            pathname: "/",
          });
        }, 1000);
      } catch (error) {
        // console.log("error ", error);
        this.setState({
          errors: error.errors,
        });
        this.setState({ isAPiCalled: false });
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };

  render() {
    const { username, password, isAPiCalled, errors } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-5 mx-auto">
            <div id="first">
              <div className="myform form ">
                <div className="logo mb-3">
                  <div className="col-md-12">
                    <h1>Sign in</h1>
                    <span>To continue to webchat</span>
                  </div>
                </div>
                {errors.map((item, index) => {
                  return (
                    <div
                      className="alert alert-danger alert-dismissible fade show"
                      role="alert"
                      key={index}
                    >
                      {item.msg}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={() => {
                          this.setState({
                            errors: [],
                          });
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  );
                })}
                <form method="post" name="login" onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      name="username"
                      className="form-control"
                      id="username"
                      aria-describedby="emailHelp"
                      placeholder="Username"
                      value={username}
                      onChange={this.setStateFromInput}
                    />
                    {this.validator.message("username", username, "required", {
                      className: "text-danger",
                    })}
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Password"
                      value={password}
                      onChange={this.setStateFromInput}
                    />
                    {this.validator.message(
                      "password",
                      password,
                      "required|min:6|max:14",
                      {
                        className: "text-danger",
                      }
                    )}
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="f-14">
                        No account?{" "}
                        <Link to="/register" className="createOneCls">
                          Create One
                        </Link>
                      </p>
                    </div>
                    <div className="col">
                      <p
                        className="f-14"
                        style={{
                          float: "right",
                        }}
                      >
                        {" "}
                        <strong>
                          <Link to="/forgot-password" className="createOneCls">
                            Forgot password?
                          </Link>
                        </strong>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <button
                        type="submit"
                        className="btn btn-block mybtn"
                        disabled={isAPiCalled}
                      >
                        Sign in
                        {isAPiCalled && (
                          <i className="fa fa-spinner ml-3 fa-pulse " />
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    user: auth,
  };
};

export default withRouter(connect(mapStateToProps, { loginUserSuccess })(Home));
